import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { PATH_DASHBOARD } from "src/path";
// hooks
import useAuth from "../hooks/useAuth";
// routes

// ----------------------------------------------------------------------

TwoFactorGuard.propTypes = {
	children: PropTypes.node,
};

export default function TwoFactorGuard({ children }) {
	const { isAuthenticated, twoFactorEnabled } = useAuth();

	if (isAuthenticated && twoFactorEnabled) {
		return <Navigate to={PATH_DASHBOARD.root} />;
	}

	return <>{children}</>;
}
