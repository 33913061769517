import {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import {setSession} from '../utils/jwt';
import storage from 'src/utils/storage';

// ----------------------------------------------------------------------

const initialState = {
	isAuthenticated: false,
	isInitialized: false,
	twoFactorEnabled: false,
	user: null,
};

const handlers = {
	INITIALIZE: (state, action) => {
		const {isAuthenticated, user} = action.payload;
		return {
			...state,
			isAuthenticated,
			isInitialized: true,
			user,
		};
	},
	LOGIN: (state, action) => {
		const {user} = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user,
		};
	},
	LOGOUT: (state) => ({
		...state,
		isAuthenticated: false,
		twoFactorEnabled: false,
		user: null,
	}),
	REGISTER: (state, action) => {
		const {user} = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user,
		};
	},
	TWO_FACTOR_ENABLED: (state, action) => {
		const {twoFactorEnabled} = action.payload;
		return {
			...state,
			twoFactorEnabled,
		};
	},
	VERIFY_TWO_FACTOR_AUTH: (state, action) => {
		const {user} = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user,
		};
	},
};

const reducer = (state, action) =>
	handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
	...initialState,
	method: 'jwt',
	login: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	register: () => Promise.resolve(),
	verify2FA: () => Promise.resolve(),
	dispatch: () => {},
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
	children: PropTypes.node,
};

function AuthProvider({children}) {
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		const initialize = async () => {
			try {
				const tokens = storage.get('ar');
				if (tokens && tokens?.access_token) {
					setSession(tokens?.access_token);

					const response = await axios.get('/api/v2/profile');
					const {user} = response.data;

					dispatch({
						type: 'INITIALIZE',
						payload: {
							isAuthenticated: true,
							user,
						},
					});
				} else {
					dispatch({
						type: 'INITIALIZE',
						payload: {
							isAuthenticated: false,
							user: null,
						},
					});
				}
			} catch (err) {
				console.error(err);
				dispatch({
					type: 'INITIALIZE',
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		};

		initialize();
	}, []);

	const login = async (phone_number, password) => {
		const response = await axios.post('/api/v2/login/customers', {
			phone_number: `234${Number(phone_number)}`,
			password,
		});
		if (response.status === 202) {
			dispatch({
				type: 'TWO_FACTOR_ENABLED',
				payload: {
					twoFactorEnabled: true,
				},
			});
		} else {
			const {access_token, user, refresh_token} = response.data;
			const tokens = {access_token, refresh_token};
			storage.set('ar', tokens);
			setSession(tokens?.access_token);
			dispatch({
				type: 'LOGIN',
				payload: {
					user,
				},
			});
		}
	};

	const verify2FA = async (token) => {
		const response = await axios.post('/api/v1/mfa', {
			token,
		});
		const {access_token, user, refresh_token} = response.data;
		const tokens = {access_token, refresh_token};
		storage.set('ar', tokens);
		setSession(tokens?.access_token);
		dispatch({
			type: 'VERIFY_TWO_FACTOR_AUTH',
			payload: {
				user,
			},
		});
	};

	const register = async (email, password, firstName, lastName, role, phoneNumber) => {
		const response = await axios.post('/api/v2/customers', {
			email,
			password,
			role,
			first_name: firstName,
			last_name: lastName,
			phone_number: `234${Number(phoneNumber)}`,
		});
		const {access_token, user, refresh_token} = response.data;
		const tokens = {access_token, refresh_token};
		storage.set('ar', tokens);
		setSession(tokens?.access_token);
		dispatch({
			type: 'REGISTER',
			payload: {
				user,
			},
		});
	};

	const logout = async () => {
		setSession(null);
		storage.clear();
		dispatch({type: 'LOGOUT'});
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'jwt',
				login,
				logout,
				register,
				dispatch,
				verify2FA,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export {AuthContext, AuthProvider};
