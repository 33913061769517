import {useLocation} from 'react-router-dom';
// @mui
import {styled, useTheme} from '@mui/material/styles';
import {AppBar, Toolbar, Container, Button, Stack} from '@mui/material';

import {ReactComponent as BLogo} from 'src/assets/black-logo.svg';
// config
import {HEADER} from '../../config';
// components
// import Logo from '../../components/Logo';
import {Link as RouterLink} from 'react-router-dom';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import useResponsive from 'src/hooks/useResponsive';
import useOffSetTop from 'src/hooks/useOffSetTop';
import cssStyles from 'src/utils/cssStyles';
import {PATH_AUTH} from 'src/path';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
	height: HEADER.MOBILE_HEIGHT,
	transition: theme.transitions.create(['height', 'background-color'], {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
	[theme.breakpoints.up('md')]: {
		height: HEADER.MAIN_DESKTOP_HEIGHT,
	},
}));

const ToolbarShadowStyle = styled('div')(({theme}) => ({
	left: 0,
	right: 0,
	bottom: 0,
	height: 24,
	zIndex: -1,
	margin: 'auto',
	borderRadius: '50%',
	position: 'absolute',
	width: `calc(100% - 48px)`,
	boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
	const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

	const theme = useTheme();

	const {pathname} = useLocation();

	const isDesktop = useResponsive('up', 'md');

	const isHome = pathname === '/';

	return (
		<AppBar sx={{boxShadow: 0, bgcolor: 'transparent'}}>
			<ToolbarStyle
				disableGutters
				sx={{
					...(isDesktop && {...cssStyles(theme).bgBlur({color: '#000', opacity: 0.17})}),
					...(isOffset && {...cssStyles(theme).bgBlur()}),
					height: {md: HEADER.MAIN_DESKTOP_HEIGHT - 16},
				}}
			>
				<Container
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					{(isDesktop || isOffset) && (
						<img src={require('src/assets/logoi.png')} width={'120'} height={'32'} alt="Carviva" />
					)}
					{!isDesktop && !isOffset && <BLogo width={120} height={32} />}
					{isDesktop && (
						<Stack direction="row" gap={1}>
							<Button
								sx={{
									color: '#ffff',
									border: '1px solid #ffff',
								}}
								color="inherit"
								component={RouterLink}
								to={PATH_AUTH.login}
								variant="outlined"
							>
								Login
							</Button>
							<Button
								sx={{
									color: '#ffff',
									border: '1px solid #ffff',
								}}
								// white color
								color={'inherit'}
								component={RouterLink}
								to={PATH_AUTH.register}
								variant="outlined"
							>
								Sign up
							</Button>
						</Stack>
					)}
					{/* <Box sx={{flexGrow: 1}} /> */}
					{/* {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
					{!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
				</Container>
			</ToolbarStyle>

			{isOffset && <ToolbarShadowStyle />}
		</AppBar>
	);
}
