// @mui
import React from 'react';

import {styled} from '@mui/material/styles';
import {
	Grid,
	Divider,
	Container,
	Typography,
	Stack,
	Link,
} from '@mui/material';
import Logo from '../../components/Logo';
import {useState} from 'react';
import Terms from 'src/sections/home/Terms';
import PrivacyPolicy from 'src/sections/home/PrivacyPolicy';


const RootStyle = styled('div')(({theme}) => ({
	position: 'relative',
	backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
	const [openDialog1, setOpenDialog1] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);


	const handleClick1 = () => setOpenDialog1(!openDialog1);
	const handleClick2 = () => setOpenDialog2(!openDialog2);

	const LINKS = [
		{
			headline: 'Carviva',
			children: [
				{name: 'About us', href: 'https://www.mycarviva.com/about/'},
				{name: 'Contact us', href: 'https://www.mycarviva.com/contact/'},
				// {name: 'FAQs', href: ''},
			],
		},
		{
			headline: 'Legal',
			children: [
				{name: 'Terms and Condition', action: handleClick1},
				{name: 'Privacy Policy', action: handleClick2},
			],
		},
		{
			headline: 'Contact',
			children: [
				{name: 'support@mycarviva.com', href: '#'},
				{name: 'Hub 43, CMD-Jubilee Road, Magodo Phase 2, Lagos', href: '#'},
			],
		},
	];
	return (
		<>
			<RootStyle>
				<Divider />
				<Container sx={{pt: 10}}>
					<Grid
						container
						justifyContent={{xs: 'center', md: 'space-between'}}
						sx={{textAlign: {xs: 'center', md: 'left'}}}
					>
						<Grid item xs={12}  sx={{mb: 3}}>
              {/* <img src={require('src/assets/logoi.png')} width={'188'} height={'52'} style={{
                margin: '0'
              }} /> */}
							<Logo />
						</Grid>
						<Grid item xs={8} md={3}>
							<Typography variant="body2" sx={{pr: {md: 5}}}>
								Carviva Fuel Wallet is a simple and secure solution that effectively turns mobile
								phone numbers into virtual wallets for buying fuel and gas at filling stations.
							</Typography>
							{/* 
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack> */}
						</Grid>

						<Grid item xs={12} md={7}>
							<Stack
								spacing={5}
								direction={{xs: 'column', md: 'row'}}
								justifyContent="space-between"
							>
								{LINKS.map((list) => (
									<Stack key={list.headline} spacing={2}>
										<Typography component="p">{list.headline}</Typography>
										{list.children.map((link) => (
											<Typography
												// to={link.href}
												key={link.name}
												color="inherit"
												variant="body2"
												// component={RouterLink}

												sx={{display: 'block', textDecoration: 'none'}}
											>
												{link.href && (
													<a
														href={link.href}
														style={{display: 'block', textDecoration: 'none', color: 'inherit'}}
														target="_blank"
														rel="noreferrer noopener"
													>
														{link.name}
													</a>
												)}
												{link.action && (
													<Link
														style={{
															display: 'block',
															textDecoration: 'none',
															color: 'inherit',
															cursor: 'pointer',
														}}
														onClick={link.action}
													>
														{link.name}
													</Link>
												)}
											</Typography>
										))}
									</Stack>
								))}
							</Stack>
						</Grid>
					</Grid>

					<Typography
						component="p"
						variant="body2"
						sx={{
							mt: 10,
							pb: 5,
							fontSize: 13,
							textAlign: {xs: 'center', md: 'left'},
						}}
					>
						©{new Date().getFullYear()}. All rights reserved
					</Typography>
				</Container>
			</RootStyle>

			<Terms openDialog1={openDialog1} handleClick1={handleClick1} />
			<PrivacyPolicy openDialog1={openDialog2} handleClick1={handleClick2} />
		</>
	);
}
