import Iconify from '../../components/Iconify';


const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: "Login",
    icon: <Iconify icon={'eva:log-in-outline'} {...ICON_SIZE} />,
    path: 'auth/login',
  },
  {
    title: "Register",
    icon: <Iconify icon={'eva:person-add-outline'} {...ICON_SIZE} />,
    path: 'auth/register',
  }
];

export default menuConfig;
